/* You can add global styles to this file, and also import other style files */
@import 'ngx-toastr/toastr';

:root {
  --golden: rgb(208, 176, 132);
  --golden-dark: #{darken(#D0B084FF, 20)};
  //--red: #DE022B;
  //--red: #EF3054;
  --red: #CC2936;
  --dark-red: #{darken(#CC2936, 5)};
  --light-red: #{lighten(#CC2936, 3)};
  //--dark-grey: #1B1B1C;
  //--dark-grey: #33312E;
  --dark-grey: #28272C;
  --blue: #087E8B;
  --blue-light: #64B6AC;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Restaurant';
  src: url('/assets/font/Restaurant.otf') format('opentype');
}

@font-face {
  font-family: 'Inconsolata';
  src: url('/assets/font/Inconsolata.ttf') format('truetype');
}

@font-face {
  font-family: 'Playball';
  src: url('/assets/font/Playball.ttf') format('truetype');
}

@font-face {
  font-family: 'Sriracha';
  src: url('/assets/font/Sriracha.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  src: url('/assets/font/Roboto.ttf') format('truetype');
}


body, html {
  //background-color: var(--dark-grey);
  box-sizing: border-box;
}

h1 {
  font-size: 1.8rem;
  font-family: "Sriracha", ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto;
}

h2 {
  font-size: 1.4rem;
  font-family: "Sriracha", ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto;
}

h3 {
  font-size: 1.2rem;
  font-family: "Sriracha", ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto;
}

h4 {
  font-size: 1.1rem;
}

.accent {
  color: var(--golden);
}



.bg-accent {
  background-color: var(--golden);
}

html, body {
  font-family: "Roboto", ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
